import {
  requestValidateStatus,
  registerPrivateAxios,
  setCheckoutHandler,
  setTokenExpireHandler,
  setExceptionHandler,
  queryBackendApi
} from '../utils/request';

// 默认15秒, ms
const DEFAULT_REQUEST_TIMEOUT = 15000;
const REQUEST_TIMEOUT = +window.settings?.requestTimeout || DEFAULT_REQUEST_TIMEOUT;

const DEFAULT_AXIOS_NAMESPACE = 'default';

// backend private api axios实例配置
const getDefaultAxiosConfig = token => ({
  baseURL: window.settings?.defaultUrl,
  timeout: REQUEST_TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token
  },
  responseType: 'json',
  validateStatus: requestValidateStatus
});

// 创建private axios 实例配置器
const createPrivateAxiosInstanceConfigs = () => {
  return {
    [DEFAULT_AXIOS_NAMESPACE]: getDefaultAxiosConfig
  };
};

// 注册private请求
const registerPrivateRequest = token => {
  registerPrivateAxios(createPrivateAxiosInstanceConfigs(), token);
};

// 初始化请求配置
const initRequestParams = () => {
  // 402唯一登录处理器
  setCheckoutHandler(() => {});

  // 401令牌过期处理器
  setTokenExpireHandler(() => {});

  // 统一异常处理器
  setExceptionHandler(excetipon => {
    window.console.log('excetipon: ', excetipon);
  });
};

export { registerPrivateRequest, initRequestParams, queryBackendApi };
