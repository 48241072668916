import Vue from 'vue';
import {
  Button,
  Select,
  Dialog,
  Input,
  Option,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Table,
  TableColumn,
  Pagination,
  Message,
  Loading,
  Cascader,
  Upload
} from 'element-ui';

import 'element-ui/lib/theme-chalk/button.css';
import 'element-ui/lib/theme-chalk/select.css';
import 'element-ui/lib/theme-chalk/dialog.css';
import 'element-ui/lib/theme-chalk/input.css';
import 'element-ui/lib/theme-chalk/option.css';
import 'element-ui/lib/theme-chalk/message.css';
import 'element-ui/lib/theme-chalk/cascader.css';

const element = [
  Button,
  Select,
  Dialog,
  Input,
  Option,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Table,
  TableColumn,
  Pagination,
  Cascader,
  Upload
];

element.forEach(el => {
  Vue.use(el);
});

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
