/**
 * 默认的executor数据转换器
 * @param {module} mod require.context批量导入的某个模块
 * @param {any} param 传递给请求模块的额外参数
 * @param {object} result 外部传入的转换结果对象
 */
const defaultTransform = (mod, param, result) => {
  const payload = mod.default(param);
  Object.assign(result, payload);
};

/**
 * 对require.context的调用结果进行转换处理, 默认行为为合并
 * @param {object} req require.context()调用结果
 * @param {any} param 传递给请求模块的额外参数
 * @param {function} transform (mod, param, result) => void
 * @param {object} result 集中处理返回结果对象, 默认为{}
 * @return {object} 处理后的require.context调用结果
 */
const executor = (req, param, { transform = defaultTransform, result = {} } = {}) => {
  if (req) {
    req.keys().forEach(key => transform(req(key), param, result));
    return result;
  }
};

export { executor };
