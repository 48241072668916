/**
 * 统一结果结构, 即将node api, backend api及其他三方api返回的异构响应数据统一转换为工程级标准结构
 * 调用成功指业务级调用成功(如新增成功)
 * 当业务级调用成功时, 仍可能存在部分业务逻辑失败的情况, 此时最终返回结果将包含error属性
 * 调用失败包含业务级调用失败(如新增失败), 以及因为客户端环境(如网络中断, 超时等)造成的系统级调用失败
 * 调用成功时返回的结构为:
 *   ok: true 必须
 *   data: ... 可选, 服务端返回的业务数据主体
 *   message: ... 可选, 服务端返回的业务消息
 *   code: ... 可用于额外的响应控制
 *   ext: ... 可选, 用于额外数据的补充(仅backend api时存在)
 *   extend: ... 可选, 使用axios发出请求时, 获取的额外http请求信息
 *   error: ... 可选, 用于ok为true但仍包含部分业务逻辑错误的情况, 用于描述用于可读的错误信息(与message分开是非常明智的决定)
 * 调用失败时返回的结构为:
 *   ok: false 必须
 *   error: ... 必须, 失败原因
 *   data: ... 可选, 失败时附带的错误信息
 *   code: ... 可用于额外的响应控制
 *   ext: ... 可选, 用于额外数据的补充(仅backend api时存在)
 *   extend: ... 可选, 使用axios发出请求时, 获取的额外http请求信息
 *
 * node api直接返回的数据包含固定结构, 包括:
 *   ok: boolean 必须
 *   code: number 必须
 *   payload: any 可选
 *   message: string 可选
 * backend api直接返回的数据包含固定结构, 包括:
 *   code: number 必须, 0表示成功, 其他表示失败
 *   data: any 可选
 *   message: any 可选
 *   ext: any 可选
 *
 */

import { createInstance, setInstance } from './axios';

const REQUEST_VALIDATE_STATUE_LIST = [301, 304, 401, 500];

// 后端token过期时的返回码
const BACKEND_TOKEN_EXPIRE_RESPONSE_CODES = [401, 425, 426,1002];
const BACKEND_TOKEN_EXPIRE_RESPONSE_MESSAGE = '本次登录已过期, 请重新登录';

// 后端单点登录冲突时的返回码
const BACKEND_UNIQUE_LOGIN_CHICKOUT_RESPONSE_CODE = 402;
const BACKEND_UNIQUE_LOGIN_CHICKOUT_RESPONSE_MESSAGE = '账号在它处登录';

// 后端强制异常检测CODE
const BACKEND_FORCECHECK_CODES = [...BACKEND_TOKEN_EXPIRE_RESPONSE_CODES, BACKEND_UNIQUE_LOGIN_CHICKOUT_RESPONSE_CODE];

// 后端强制性error检查器
const backendForceErrorChecker = ({ code, payload, message, ext }) => {
  // 后端唯一登录冲突, 跳转至登录页并提供对应参数信息
  if (code === BACKEND_UNIQUE_LOGIN_CHICKOUT_RESPONSE_CODE) {
    const error = new Error(BACKEND_UNIQUE_LOGIN_CHICKOUT_RESPONSE_MESSAGE);
    error.code = BACKEND_UNIQUE_LOGIN_CHICKOUT_RESPONSE_CODE;
    error.backend = { data: payload, message, ext };
    throw error;
  }

  // 后端token过期, 跳转至登录页并提供对应参数信息
  if (BACKEND_TOKEN_EXPIRE_RESPONSE_CODES.includes(code)) {
    const error = new Error(BACKEND_TOKEN_EXPIRE_RESPONSE_MESSAGE);
    error.code = code;
    error.backend = { data: payload, message, ext };
    throw error;
  }
};

// 后端强制性错误处理器
const backendForceErrorHandler = error => {
  // 唯一登录异常处理
  if (error.code === BACKEND_UNIQUE_LOGIN_CHICKOUT_RESPONSE_CODE) {
    if (checkoutHandler) {
      checkoutHandler(error);
    } else {
      throw error;
    }
  }

  // token过期异常处理
  if (BACKEND_TOKEN_EXPIRE_RESPONSE_CODES.includes(error.code)) {
    if (tokenExpireHandler) {
      tokenExpireHandler(error);
    } else {
      throw error;
    }
  }
};

// 唯一登录错误默认处理器
let checkoutHandler = null;

// 设置唯一登录错误处理器
const setCheckoutHandler = hander => {
  checkoutHandler = hander;
};

// token过期默认处理器
let tokenExpireHandler = null;

const setTokenExpireHandler = handler => {
  tokenExpireHandler = handler;
};

// 统一异常默认处理器
let exceptionHandler = null;

const setExceptionHandler = handler => {
  exceptionHandler = handler;
};

// 默认的全局请求注入配置获取方法
let getBackendGlobalRequestConfig = () => ({ headers: {} });

const setGetBackendGlobalRequestConfig = handler => {
  getBackendGlobalRequestConfig = handler;
};

/** 从axios请求抛出的异常中获取message信息
 * @param {Object} error axios抛出的异常
 * @returns {Object} {error:'meeage'} 返回异常中包含的
 */
const extraAxiosResponseErrorMessage = error => {
  if (error.response) {
    // 非2xx response
    return {
      error: '服务器响应错误'
    }; // 'SERVER RESPONSE ERROR'
  } else if (error.request) {
    return {
      error: '服务器无响应',
      timeout: true
    }; // 'SERVER NO RESPONSE'
  } else {
    return {
      error: '请求参数错误'
    }; // 'INVALID REQUEST'
  }
};

/**
 * 解析调用backend api时axios返回的数据
 * @param {Object} axiosResponse axios调用backend api返回的数据
 * @returns 统一结果结构
 */
const parserBackendApiResponse = axiosResponse => {
  // 通过axios status valid的response
  const { data, status, statusText, headers } = axiosResponse;

  // data为backend api所返回的结果数据, 包含固定的属性
  if (data) {
    const { code: codeString, data: payload, msg, ext } = data;
    const code = +codeString;
    const message = msg || data.message;

    if (codeString == undefined) {
      // 主要针对服务器异常
      const { error, message } = data;
      return { ok: false, message: error || message, data, ext, extend: { status, statusText, headers } };
    }
    // 处理为工程级统一响应格式, 用来屏蔽node api, backend api及其他三方api之间的响应数据结构差异
    // backend api中使用code属性表示调用是否成功, 200成功, 401令牌过期, 402单点登录冲突, 其他>0情况为部分成功, 小于0时失败
    // 请求成功
    if (code === 200) {
      return { ok: true, data: payload, message, code, ext, extend: { status, statusText, headers } };
    }

    // // 请求部分成功(注意此时存在error属性而不是message属性)
    // if (code !== 200 && !~BACKEND_FORCECHECK_CODES.indexOf(code)) {
    //   return { ok: true, data: payload, error: message, message, code, ext, extend: { status, statusText, headers } };
    // }

    // 后端强制性error检测, 存在401/402时将抛出异常
    backendForceErrorChecker({ code, payload, message, ext });
    // 请求错误(backend api返回的错误信息可能保存于data中)
    return { ok: false, error: message, data: payload, message, ext, code, extend: { status, statusText, headers } };
  }
};

/**
 * 封装axios调用backend api的请求
 * @param {Object} axios backend api对应的axios实例
 * @param {String} path backend api路径(不包含base url)
 * @param {Object} option axios所需参数, 可包含params等属性
 * @param {String} method http请求方法, 默认为get
 * @param {Object} extend 自定义的一些参数 主要是 config的一些参数
 * @returns 处理结果, 调用成功时为{ data: something }, 调用失败时为 { error: someerr }
 */
const queryBackendApi = async (axios, path, option, method = 'get', extend = {}) => {
  let result = null;
  try {
    let axiosResponse = null;
    let config = await getBackendGlobalRequestConfig();

    // 适配axios
    method = method.toLowerCase();
    if (method === 'put' || method === 'post') {
      const oldHeaders = { ...config.headers };
      config = Object.assign({}, config, extend);
      const extendHeaders = extend.headers || {};
      config.headers = Object.assign({}, oldHeaders, extendHeaders);
      // 此时option等同data效果
      axiosResponse = await axios[method](path, option, config);
    } else {
      if (option) {
        // 目前仅对于非put/post请求添加定制的config功能(目前支持headers)
        config.params = option.params;
        config.headers = Object.assign({}, config.headers, option.headers);
      }
      axiosResponse = await axios[method](path, config);
    }

    // 这里的result为工程级统一响应结构, ok属性或error属性用于判断是否调用成功
    //  注意业务级失败也作为失败处理
    result = parserBackendApiResponse(axiosResponse);
  } catch (error) {
    // 后端强制性error处理
    backendForceErrorHandler(error);
    result = { ok: false, ...extraAxiosResponseErrorMessage(error) };
  }

  // 统一异常处理, 此处只针对所有异常中的三方异常进行处置(注意以下异常包括部分成功的情况)
  // 使用result.error判断是否存在错误或部分成功的情况(不再使用result.ok作为判断依据, 因为无法鉴别部分成功的情况)
  if (result.error && exceptionHandler) {
    // error: 错误可读信息
    // ext: 错误明细信息
    // headers: 响应头
    exceptionHandler({
      error: result.error,
      code: result.code,
      ext: result.ext,
      headers: result.extend.headers || []
    });
  }

  return result;
};

// 请求响应的http状态码验证规则
const requestValidateStatus = status =>
  (status >= 200 && status < 300) || REQUEST_VALIDATE_STATUE_LIST.includes(status);

// 注册public api(如login, reload等)
const registerPublicAxios = axiosInstanceConfigs => {
  Object.entries(axiosInstanceConfigs).forEach(([key, getConfig]) => {
    setInstance(createInstance(getConfig()), key);
  });
};

// 注册private api
const registerPrivateAxios = (axiosInstanceConfigs, token) => {
  Object.entries(axiosInstanceConfigs).forEach(([key, getConfig]) => {
    setInstance(createInstance(getConfig(token)), key);
  });
};

export {
  queryBackendApi,
  registerPublicAxios,
  registerPrivateAxios,
  requestValidateStatus,
  setCheckoutHandler,
  setTokenExpireHandler,
  setGetBackendGlobalRequestConfig,
  setExceptionHandler
};
