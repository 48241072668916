import axios from 'axios';

const instances = {};

const createInstance = option => axios.create(option);

const setInstance = (instance, namespace) => {
  instances[namespace] = instance;
};

const getInstance = namespace => instances[namespace];

export { createInstance, setInstance, getInstance };
