<template>
  <div
    id="app"
    v-loading="globalLoading"
    :class="$style.app"
    element-loading-text="loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <router-view />
  </div>
</template>

<script>
import { SESSION_KEY_TOKEN, SESSION_KEY_USER } from '@/const/sessionStorage.js';
import createMixin from '@/utils/vuex-helper';

const globalMinxins = createMixin('global', {
  states: {
    globalLoading: 'loading'
  },
  mutations: ['setDcId'],
  actions: ['actionToken']
});

export default {
  mixins: [globalMinxins],
  data() {
    return {
      menus: []
    };
  },
  created() {
    this.reloadInitGlobalStore();
  },
  mounted() {
    this.initFontSize();
    window.addEventListener('resize', this.initFontSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.initFontSize);
  },
  methods: {
    reloadInitGlobalStore() {
      let dcId = '';
      let token = '';
      if (process.env.NODE_ENV == 'development') {
        // 开发调试数据
        // dcId = '832734919813365790';
        // token = 'L62ThvN4gHkz46L3YHYeuUrxkTG79z';
      } else {
        // 主要是针对刷新时候使用
        const dcIdString = localStorage.getItem(SESSION_KEY_USER);
        if (dcIdString) {
          try {
            dcId = JSON.parse(dcIdString)?.dcId;
            // eslint-disable-next-line no-empty
          } catch (error) {}
        }
        token = localStorage.getItem(SESSION_KEY_TOKEN) || '';
      }
      this.setDcId(dcId);
      this.actionToken(token);
    },
    initFontSize() {
      //得到手机屏幕的宽度
      let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
      //得到html的Dom元素
      let htmlDom = document.getElementsByTagName('html')[0];
      //设置根元素字体大小
      // htmlWidth = htmlWidth > 750 ? htmlWidth : 750;
      htmlDom.style.fontSize = htmlWidth / 20 + 'px';
    }
  }
};
</script>

<style lang="scss" module>
@import './styles/common';
:global {
  .el-message {
    font-size: px2rem(16) !important;
  }
}

.app {
  height: 100%;
  overflow: hidden;
  font-family: PingFang;
}
</style>
