import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const getRoutes = () => [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: 'Auth' */ '../views/auth/Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'Login' */ '../views/login/Index.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import(/* webpackChunkName: 'Login' */ '../views/login/ResetPassword.vue')
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: () => import(/* webpackChunkName: 'Login' */ '../views/login/SignUp.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: 'Home' */ '../views/home/Index.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: 'Dashboard' */ '../views/dashboard/Index.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import(/* webpackChunkName: 'Purchase' */ '../views/purchase/Index.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import(/* webpackChunkName: 'recharge' */ '../views/recharge/Index.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import(/* webpackChunkName: 'Policy' */ '../views/policy/Index.vue')
  },
  {
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: 'Service' */ '../views/service/Index.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: 'history', // 路由有两种模式：history、hash，想要不带#号就选history，默认是hash模式
  routes: getRoutes()
});

export default router;
