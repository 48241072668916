import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';

class VuexAccessor {
  constructor(Global, namespace) {
    this.Global = Global;
    this.namespace = namespace;
    this.stateBasePath = this.namespace.join('.');
    this.getterBasePath = this.namespace.join('/');
    this.commitBasePath = this.namespace.join('/');
    this.dispatchBasePath = this.namespace.join('/');
  }

  /** 获取state */
  state(key) {
    return _.get(this.Global.store.state, `${this.stateBasePath}.${key}`);
  }

  /** 获取getter */
  getter(key, param) {
    return this.Global.store.getters[`${this.getterBasePath}/${key}`](param);
  }

  /** commit */
  commit(key, param) {
    return this.Global.store.commit(`${this.commitBasePath}/${key}`, param);
  }

  /** action */
  dispatch(key, param) {
    return this.Global.store.dispatch(`${this.dispatchBasePath}/${key}`, param);
  }
}

class VuexMixinFactory {
  static createMixin(namespace, { states, getters, mutations, actions }) {
    const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

    const mixin = {};

    let computed = {};

    if (states) {
      computed = mapState(states);
    }

    if (getters) {
      computed = { ...computed, ...mapGetters(getters) };
    }

    mixin.computed = computed;

    let methods = {};

    if (mutations) {
      methods = mapMutations(mutations);
    }

    if (actions) {
      methods = { ...methods, ...mapActions(actions) };
    }

    mixin.methods = methods;

    return mixin;
  }
}

export { VuexAccessor, VuexMixinFactory };
export default VuexMixinFactory.createMixin;
