import Vue from 'vue';
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import { init } from './setup';
import Global from './utils/global';

// Vue.use(ElementUI);
//引入element-ui中的组件
import './components/element';

Vue.config.productionTip = false;

const config = init();

Global.setGlobal(config); // 在Vue实例创建前设置Global

const root = new Vue({
  ...config,
  render: h => h(App)
}).$mount('#app');

Global.root = root;
